/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import { withStyles } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PropTypes from 'prop-types';
import React from 'react';

import { IMAGE_ORIGIN, IMAGE_SECRET } from '../actions/imageActions';

const styles = {
  root: {
  },
  container: {
    margin: '28px 88px',
  },
  imageContainer: {
    display: 'flex',
    alignSelf: 'center',
    height: 360,
    width: 360,
    outline: '5px dotted gray',
    outlineOffset: -40,
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 16,
    marginBottom: 16,
  },
  uploadButton: {
  },
  uploadInput: {
    display: 'none',
  },
  rightIcon: {
    marginLeft: 8,
  },
};

class ImageView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageSrc: '',
    };
  }

  render() {
    const {
      classes: {
        buttonContainer,
        container,
        imageContainer,
        rightIcon,
        uploadButton,
        uploadInput,
      },
      id,
      addOriginImage,
      addSecretImage,
    } = this.props;
    const { imageSrc } = this.state;

    const onClickFileSelector = event => {
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log(reader.result.split(',').pop());
        this.setState({ imageSrc: reader.result });
        if (id === IMAGE_ORIGIN) {
          addOriginImage(reader.result.split(',').pop());
        }
        if (id === IMAGE_SECRET) {
          addSecretImage(reader.result.split(',').pop());
        }
      };
      reader.readAsDataURL(event.target.files[0]);
    };

    return (
      <Card className={container}>
        <CardMedia
          className={imageContainer}
          title="Paella dish"
          image={imageSrc}
        />
        <CardActions className={buttonContainer}>
          <input
            accept="image/*"
            className={uploadInput}
            id={id}
            type="file"
            onInput={onClickFileSelector}
          />
          <label htmlFor={id}>
            <Button variant="contained" color="default" component="span" className={uploadButton}>
              Upload
              <CloudUploadIcon className={rightIcon} />
            </Button>
          </label>
        </CardActions>
      </Card>
    );
  }
}

ImageView.propTypes = {
  classes: PropTypes.shape({
    buttonContainer: PropTypes.shape({}).isRequired,
    container: PropTypes.shape({}).isRequired,
    imageContainer: PropTypes.shape({}).isRequired,
    rightIcon: PropTypes.shape({}).isRequired,
    uploadButton: PropTypes.shape({}).isRequired,
    uploadInput: PropTypes.shape({}).isRequired,
  }).isRequired,
  id: PropTypes.string.isRequired,
  addOriginImage: PropTypes.func.isRequired,
  addSecretImage: PropTypes.func.isRequired,
};

ImageView.defaultProps = {
};

export default withStyles(styles)(ImageView);
