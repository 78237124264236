import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { IMAGE_ORIGIN, IMAGE_SECRET } from '../actions/imageActions';
import ImageView from '../containers/imageView';

const styles = {
  root: {
    marginTop: 56,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: 1440,
    marginTop: 128,
    marginBottom: 48,
  },
  subContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center',
    maxWidth: 1440,
    marginBottom: 48,
  },
  mergedImage: {
    width: 360,
    height: 360,
  },
};

const ImageGroupView = ({
  classes: {
    container,
    subContainer,
    mergedImage,
  },
  embed,
  embeddedImage,
}) => (
  <Container maxWidth="xl" className={container}>
    <Container maxWidth="md" className={subContainer}>
      <ImageView id={IMAGE_ORIGIN} />
      <ImageView id={IMAGE_SECRET} />
    </Container>
    <Container maxWidth="md" className={subContainer}>
      <Button
        variant="contained"
        color="default"
        component="span"
        onClick={embed}
      >
        Upload
      </Button>
    </Container>
    {embeddedImage && (
      <Container maxWidth="md" className={subContainer}>
        <img className={mergedImage} src={`data:image/png;base64, ${embeddedImage}`} alt="" />
      </Container>
    )}
  </Container>
);

ImageGroupView.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.shape({}).isRequired,
    subContainer: PropTypes.shape({}).isRequired,
    mergedImage: PropTypes.shape({}).isRequired,
  }).isRequired,
  embed: PropTypes.func.isRequired,
  embeddedImage: PropTypes.string.isRequired,
};

ImageGroupView.defaultProps = {
};

export default withStyles(styles)(ImageGroupView);
