import { connect } from 'react-redux';

import * as imageActions from '../actions/imageActions';
import ImageGroupViewComponent from '../components/imageGroupView';

const mapStateToProps = (state, ownProps) => ({
  embeddedImage: state.image.embeddedImage,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  embed: () => { dispatch(imageActions.embedImage()); },
});

const ImageGroupView = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ImageGroupViewComponent);

export default ImageGroupView;
