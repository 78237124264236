import { connect } from 'react-redux';

import * as imageActions from '../actions/imageActions';
import ImageViewComponent from '../components/imageView';

const mapStateToProps = (state, ownProps) => ({
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  addOriginImage: imageEncoded => { dispatch(imageActions.addOriginImage(imageEncoded)); },
  addSecretImage: imageEncoded => { dispatch(imageActions.addSecretImage(imageEncoded)); },
});

const ImageView = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ImageViewComponent);

export default ImageView;
